<ng-container *ngIf="shipment as s">
  <div class="pill-icon">
    @if (s.type === 'DELIVERY') {
      <fa-icon icon="truck"></fa-icon>
    } @else {
      <fa-icon icon="truck-arrow-right" [flip]="'horizontal'"></fa-icon>
    }
  </div>
  <div class="pill-body">
    <div class="pill-line">
      <b>
        <i>{{ 'ShipmentType.' + s.type | l }}</i>
      </b>
    </div>
    <div class="pill-label" [xClip]="s.reference ?? ''">
      {{ s.reference }}
    </div>
    <div class="pill-line">
      {{ 'ShipmentState.' + s.state | l }}
    </div>
    <div class="pill-line">
      @if (s.method) {
        <xd-shape [color]="s.method.color"></xd-shape>
        {{ s.method.name }}
      } @else {
        <i>- No shipping method assigned -</i>
      }
    </div>
  </div>
  <div class="pill-body" *ngIf="s.slot as slot">
    <div class="pill-label">
      {{ slot.startAt | datetime: 'mediumDate' : s.method?.timezone }}
    </div>
    <div class="pill-line">
      {{ slot.startAt | datetime: 'shortTime' : s.method?.timezone }}
    </div>
  </div>
</ng-container>
