import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { CountryFlagModule } from '@x/common/country-flag';
import { DataModule } from '@x/common/data';
import { DateTimeModule } from '@x/common/datetime';
import { FormCommonModule } from '@x/common/form';
import { IconModule } from '@x/common/icon';
import { LocaleModule } from '@x/common/locale';
import { MarkdownModule } from '@x/common/markdown';
import { MetaModule } from '@x/common/meta';
import { MoneyModule } from '@x/common/money';
import { OperationModule } from '@x/common/operation';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardFormModule } from '@x/dashboard/form';
import { DashboardGridModule } from '@x/dashboard/grid';
import { DashboardMenuModule } from '@x/dashboard/menu';
import { EcommerceDomainClientModule } from '@x/ecommerce/domain-client';
import { EcommerceDomainDataModule } from '@x/ecommerce/domain-data';
import { GeocodeClientModule } from '@x/geocode/client';
import { ADDRESS_LANGUAGE } from './address.language';
import { AddressColumnComponent } from './components/address-column/address-column.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressIndexDisplayComponent } from './components/address-index-display/address-index-display.component';
import { AddressInputDialogComponent } from './components/address-input-dialog/address-input-dialog.component';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { AddressPillComponent } from './components/address-pill/address-pill.component';
import { AddressTableComponent } from './components/address-table/address-table.component';
import { USER_ADDRESS_INDEX_LANGUAGE } from './components/address-table/user-address-index.language';
import { BulkOrderEntryPillComponent } from './components/bulk-order-entry-pill/bulk-order-entry-pill.component';
import { BulkOrderPillComponent } from './components/bulk-order-pill/bulk-order-pill.component';
import { CartQuantityControlComponent } from './components/cart-quantity-control/cart-quantity-control.component';
import { ChannelPillComponent } from './components/channel-pill/channel-pill.component';
import { ChannelSelectControlComponent } from './components/channel-select-control/channel-select-control.component';
import { ChannelSelectComponent } from './components/channel-select/channel-select.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { DimensionFormInputComponent } from './components/dimension-form-input/dimension-form-input.component';
import { GeoRegionPillComponent } from './components/geo-region-pill/geo-region-pill.component';
import { LocaleSelectComponent } from './components/locale-select/locale-select.component';
import { MemberPillComponent } from './components/member-pill/member-pill.component';
import { NotificationPillComponent } from './components/notification-pill/notification-pill.component';
import { OrderPaymentPillComponent } from './components/order-payment-pill/order-payment-pill.component';
import { OrderPillComponent } from './components/order-pill/order-pill.component';
import { OrderTagPillComponent } from './components/order-tag-pill/order-tag-pill.component';
import { ParcelPillComponent } from './components/parcel-pill/parcel-pill.component';
import { PaymentPillComponent } from './components/payment-pill/payment-pill.component';
import { ShipmentPillComponent } from './components/shipment-pill/shipment-pill.component';
import { SubscriberPillComponent } from './components/subscriber-pill/subscriber-pill.component';
import { SubscriberTableComponent } from './components/subscriber-table/subscriber-table.component';
import { SubscriberTagPillComponent } from './components/subscriber-tag-pill/subscriber-tag-pill.component';
import { TaxonPillComponent } from './components/taxon-pill/taxon-pill.component';
import { UserPaymentMethodPillComponent } from './components/user-payment-method-pill/user-payment-method-pill.component';
import { UserPillComponent } from './components/user-pill/user-pill.component';
import { WarehousePillComponent } from './components/warehouse-pill/warehouse-pill.component';
import { WarehouseSelectComponent } from './components/warehouse-select/warehouse-select.component';
import { AddressPipe } from './pipes/address.pipe';
import { AddressDialogService } from './services/address-dialog.service';

const COMPONENTS = [
  DimensionFormInputComponent,
  ParcelPillComponent,
  ShipmentPillComponent,
  AddressPillComponent,
  OrderPaymentPillComponent,
  OrderPillComponent,
  GeoRegionPillComponent,
  OrderTagPillComponent,
  LocaleSelectComponent,
  ChannelPillComponent,
  ChannelSelectControlComponent,
  ChannelSelectComponent,
  CountrySelectComponent,
  AddressColumnComponent,
  AddressFormComponent,
  AddressIndexDisplayComponent,
  AddressInputDialogComponent,
  AddressInputComponent,
  AddressTableComponent,
  AddressPipe,
  UserPillComponent,
  SubscriberPillComponent,
  SubscriberTableComponent,
  PaymentPillComponent,
  WarehouseSelectComponent,
  TaxonPillComponent,
  WarehousePillComponent,
  UserPaymentMethodPillComponent,
  BulkOrderPillComponent,
  BulkOrderEntryPillComponent,
  MemberPillComponent,
  SubscriberTagPillComponent,
  NotificationPillComponent,
  CartQuantityControlComponent,
];

const MODULES = [
  FormCommonModule,
  DashboardCoreModule,
  DashboardFormModule,
  DashboardGridModule,
  DashboardMenuModule,
  MoneyModule,
  OperationModule,
  DateTimeModule,
  RouterModule,
  CommonModule,
  MarkdownModule,
  MetaModule,
  OverlayModule,
  GoogleMapsModule,
  GeocodeClientModule,
  A11yModule,
  CountryFlagModule,
  IconModule,
  LocaleModule,
  EcommerceDomainClientModule,
  EcommerceDomainDataModule,
  DataModule,
];

const SERVICES = [AddressDialogService];

@NgModule({
  declarations: COMPONENTS,
  exports: [...COMPONENTS, ...MODULES],
  imports: [...MODULES, LocaleModule.forChild([ADDRESS_LANGUAGE, USER_ADDRESS_INDEX_LANGUAGE])],
  providers: SERVICES,
})
export class EcommerceAdminCoreModule {
  static forRoot(): ModuleWithProviders<EcommerceAdminCoreModule> {
    return {
      ngModule: EcommerceAdminCoreModule,
      providers: [],
    };
  }
}
