import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'x-cart-quantity-control',
  templateUrl: './cart-quantity-control.component.html',
  styleUrls: ['./cart-quantity-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CartQuantityControlComponent,
      multi: true,
    },
  ],
})
export class CartQuantityControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private _destroy$ = new Subject<void>();

  private onTouched = () => {};
  private onChange = (quantity: number) => {};

  @Input()
  min: number = 1;

  @Input()
  max: number | null = null;

  @Input()
  set quantity(q: number) {
    this.quantityControl.patchValue(q, { emitEvent: false });
  }
  get quantity() {
    return this.quantityControl.value;
  }

  private _disabled = false;
  public get disabled() {
    return this._disabled;
  }
  @Input()
  public set disabled(value) {
    this._disabled = value;
    if (value) {
      this.quantityControl.disable({ emitEvent: false });
    } else {
      this.quantityControl.enable({ emitEvent: false });
    }
  }

  @Input()
  debounceTime: number = 400;

  @Output()
  quantityUpdated = new EventEmitter<number>();

  quantityControl = new UntypedFormControl(1);

  get canPlus() {
    return this.max == null || this.quantity < this.max;
  }

  get canMinus() {
    return this.quantity > this.min;
  }

  ngOnInit(): void {
    this.quantityControl.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(this.debounceTime),
        tap((qty) => {
          this.onChange(qty);
          this.quantityUpdated.emit(qty);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  plus() {
    if (this.canPlus) {
      this.quantityControl.setValue(this.quantity + 1);
    }
  }

  minus() {
    if (this.canMinus) {
      this.quantityControl.setValue(this.quantity - 1);
    }
  }

  writeValue(obj: any): void {
    const qty = coerceNumberProperty(obj);
    if (this.quantity !== qty) {
      this.quantity = qty;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
